import loadable from '@loadable/component';
import { WithLazyHydration } from '../../WithLazyHydration';

const VideoModuleLoadable = loadable(() => import('./VideoModule'), {
  resolveComponent: components => components.VideoModule
});

export const VideoModule = WithLazyHydration(VideoModuleLoadable, {
  name: 'VideoModule'
});
